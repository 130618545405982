html {
    font-size: 10px;
    font-family: sans-serif;
}

p {
    font-size: 1.6rem;
    line-height: 1.5;
}

.buttoncontainer {
    display: flex;
    justify-content: center;
}

button {
    flex-basis: 48%;
    color: red;
    font-size: 15px;
    border: 2px solid #f2f2f2;
    line-height: 2;
    padding: 5px;
}

.postnumber{
    font-family: 'billabong';
    text-align: center;
    font-size: 4rem;
    font-weight: 300;
    margin: 2rem 0;
    letter-spacing: -1;
    color: black;
}

h1 {
    font-family: 'billabong';
    text-align: center;
    font-size: 13rem;
    font-weight: 300;
    margin: 2rem 0;
    letter-spacing: -1;
    color: black;
}

@font-face {
    font-family: 'billabong';
    src: url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.ttf");
}

.photogrid {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

.figures {
    flex-basis: calc(33.333% - 4rem);
    border: 2px solid #d3d3d3;
    padding: 2rem;
    flex-grow: 1;
    margin: 0 2rem 2rem 2rem;
}

.photo {
    width: calc(100% + 4rem);
    margin: -2rem 0rem 0rem -2rem;
}

.addbutton{     
margin: 20px auto;
display: table;
padding: 40px;
background: url("https://image.flaticon.com/icons/svg/60/60740.svg") center no-repeat;
background-size: contain;
border: none;
}

.form{
    background-color: white;
    max-width: 360px;
    margin: 20px auto;
    padding: 45px;
    text-align: center;
    border: 1px solid #f2f2f2;

}

.form input{
    font-family: sans-serif;
    background: #f2f2f2;
    width: 100%;
    border: none;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    margin: 0 0 15px;
}

.form button{
    font-family: sans-serif;
    text-transform: uppercase;
    background: #065784;
    color: white;
    width: 100%;
    padding: 15px;
    font-size: 14px;
    cursor: pointer;
}

.form button:hover{
    background: #7b7b7b;
}

button:hover{
    border: 2px solid blue;
}

